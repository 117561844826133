import React, { FC } from 'react';

import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, YoutubeIcon } from '@sparky/icons';

const SocialMediaIcon: FC<{ iconName: string }> = ({ iconName }) => {
  const arrSocialBrands = ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube'];
  const strippedIconName = arrSocialBrands.reduce((socialBrand, strippedIconName) =>
    iconName.toLowerCase().includes(socialBrand) ? socialBrand : strippedIconName,
  );

  switch (strippedIconName) {
    case 'youtube':
      return <YoutubeIcon />;

    case 'linkedin':
      return <LinkedInIcon />;

    case 'twitter':
      return <TwitterIcon />;

    case 'instagram':
      return <InstagramIcon />;

    case 'facebook':
    default:
      return <FacebookIcon />;
  }
};

export default SocialMediaIcon;
